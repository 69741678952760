require("./bootstrap");

import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

if (process.env.NODE_ENV == "production") {
    const analytics = Analytics({
        app: "5forummw",
        plugins: [
            googleAnalytics({
                measurementIds: ["G-B2XCD3R8N9"],
            }),
        ],
    });
    /* Track a page view */
    analytics.page();
}

function checkCategoryHiddenField() {
    if (
        $("select[name=category]")
            .children("option:selected")
            .data("needsProof") == true
    ) {
        $("#proof-document-upload").show();
        $("label[for=student_proof_document]").addClass("required");
        $("input[name=student_proof_document]").attr("required", true);
    } else {
        $("#proof-document-upload").hide();
        $("label[for=student_proof_document]").removeClass("required");
        $("input[name=student_proof_document]").attr("required", false);
    }
}
function checkIMEHiddenField() {
    if (
        $("select[name=organisation]")
            .children("option:selected")
            .data("needsProof") == true
    ) {
        $("#ime-proof-document-upload").show();
        $("label[for=ime_proof_document]").addClass("required");
        $("input[name=ime_proof_document]").attr("required", true);
    } else {
        $("#ime-proof-document-upload").hide();
        $("label[for=ime_proof_document]").removeClass("required");
        $("input[name=ime_proof_document]").attr("required", false);
    }
}
function checkPhoneField() {
    const country = COUNTRIES.find(
        (el) =>
            el?.name ==
            $("select[name=country]").children("option:selected").val()
    );
    if (country) {
        $("input[name=phone]").val("+" + country?.calling_code);
        $("input[name=mobile_phone]").val("+" + country?.calling_code);
    }
}
$(function () {
    checkCategoryHiddenField();
    // checkIMEHiddenField();
    $("select[name=category]").change(function (e) {
        checkCategoryHiddenField();
    });
    $("select[name=organisation]").change(function (e) {
        // checkIMEHiddenField();
    });
    $("select[name=country]").change(function (e) {
        checkPhoneField();
    });
});
